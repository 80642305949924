import { render, staticRenderFns } from "./SalingDetail.vue?vue&type=template&id=d7222cca&scoped=true"
import script from "./SalingDetail.vue?vue&type=script&lang=js"
export * from "./SalingDetail.vue?vue&type=script&lang=js"
import style0 from "./SalingDetail.vue?vue&type=style&index=0&id=d7222cca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7222cca",
  null
  
)

export default component.exports