<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm" spinner-variant="primary" spinner-type="grow" spinner-small>
      <div class="d-print-none">
        <div class="d-print-none d-flex justify-content-end align-items-center mr-3">
          <span @click="handleClose" class="cursor-pointer" style="font-size: 40px">&times;</span>
        </div>
        <div class="d-print-none">
          <h2 class="text-uppercase text-center">
            {{ $t("lbl_saling_detail") }}
          </h2>
          <div class="container px-4">
            <div class="d-flex justify-content-end mb-2">
              <!-- <b-button
                @click="handleEditSaling"
                v-if="paymentInfo.value===PAYMENT_TYPE[0].value"
                class="mr-2"
                variant="primary"
              >{{ $t('lbl_update_bill') }}</b-button>-->
              <b-button @click="handlePrintBill" variant="primary">{{ $t("lbl_print_saling") }}</b-button>
              <!-- <b-button @click="handleSubmitSalingForm" variant="primary">{{ $t('lbl_sale_out') }}</b-button> -->
            </div>
            <div class="row match-height">
              <div class="col-12">
                <b-card :title="$t('lbl_saling_info')" class="cs-font">
                  <div class="row">
                    <div class="col-lg-6">
                      <span>{{ $t("lbl_bill_code") }}:</span>
                      <span v-if="salingData.stock_out_code" class="ml-1 cs-text-bold">{{ salingData.stock_out_code
                        }}</span>
                    </div>
                    <div class="col-lg-6">
                      <span>{{ $t("lbl_warehouse_name") }}:</span>
                      <span v-if="salingData.inventory" class="ml-1 cs-text-bold">{{ salingData.inventory.name }}</span>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-lg-6">
                      <span>{{ $t("Tên Tổ chức") }}:</span>
                      <span class="ml-1 cs-text-bold" v-if="salingData.inventory && salingData.inventory.supplier">
                        {{ salingData.inventory.supplier.name }}
                      </span>
                    </div>
                    <div class="col-lg-6">
                      <span>{{ $t("obj_table_saling.created_at") }}:</span>
                      <span class="ml-1 cs-text-bold">{{ formatDate(salingData.date_add) }}</span>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-lg-6">
                      <span>{{ $t("lbl_full_name") }}:</span>
                      <span class="ml-1 cs-text-bold">{{ getCustomerInfo().name || "" }}</span>
                    </div>
                    <div class="col-lg-6">
                      <span>{{ $t("lbl_phone") }}:</span>
                      <span class="ml-1 cs-text-bold">{{ getCustomerInfo().phone || "" }}</span>
                    </div>
                    <!-- <div class="col-lg-3">
                      <span>{{ $t('lbl_age_short') }}:</span>
                      <span class="ml-1 cs-text-bold">{{ Number(salingData.customer_age)||''}}</span>
                    </div>-->
                    <!-- <div class="col-lg-3">
                      <span>{{ $t('lbl_gender') }}:</span>
                      <span
                        v-if="salingData.customer_gender"
                        class="ml-1 cs-text-bold"
                      >{{ $t(salingData.customer_gender===1?'lbl_male':'lbl_female')}}</span>
                    </div>-->
                  </div>
                  <div class="row mt-1">
                    <div class="col">
                      <span>{{ $t("lbl_address") }}:</span>
                      <span class="ml-1 cs-text-bold">{{ getAddress(salingData) }}</span>
                    </div>
                  </div>
                </b-card>
              </div>
              <!-- <div class="col-lg-4">
                <b-card :title="$t('lbl_payment')" class="cs-font">
                  <div class="d-flex">
                    <span>{{ $t('lbl_total_price') }}:</span>
                    <span
                      style="flex:1"
                      v-if="salingData.total_amount"
                      class="ml-1 cs-text-bold text-right"
                    >{{ formatPrice(salingData.total_amount) }}</span>
                  </div>
                  <div class="d-flex mt-1 justify-content-between align-items-end">
                    <span>{{ $t('lbl_status') }}:</span>
                    <div class="ml-1 d-flex flex-1 justify-content-end">
                      <div
                        v-click-outside="handleCloseDropDown"
                        class="cs-relative"
                        style="width:100%"
                      >
                        <div
                          @click="handleToggleDropdown"
                          class="d-flex align-items-center justify-content-center"
                          :class="{
                          'not-payment cursor-pointer justify-content-between':paymentInfo.value===PAYMENT_TYPE[0].value,
                          'payment':paymentInfo.value===PAYMENT_TYPE[1].value,
                          'reject':paymentInfo.value===PAYMENT_TYPE[2].value,
                          'status':true,
                          }"
                        >
                          <div>{{paymentInfo.label }}</div>
                          <feather-icon
                            v-if="paymentInfo.value===PAYMENT_TYPE[0].value"
                            icon="ChevronDownIcon"
                            size="20"
                            class="text-white ml-1"
                          />
                        </div>
                        <div v-if="isOpenDropdown" class="menu-select">
                          <div
                            @click="handleSelectPaymentType(item)"
                            class="cs-menu-item"
                            :class="{'cs-item-active':item.value===paymentInfo.value}"
                            v-for="item in PAYMENT_TYPE"
                            :key="item.value"
                          >
                            <span>{{ item.label }}</span>
                            <feather-icon
                              v-if="item.value===paymentInfo.value"
                              icon="CheckIcon"
                              size="20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="paymentInfo.value===PAYMENT_TYPE[1].value" class>
                    <div class="d-flex justify-content-end">
                      <span>
                        <i style="font-size:12px">
                          (
                          <span
                            v-if="receiptData.collect_user"
                          >{{ `${receiptData.collect_user.name} ` }}</span>
                          <span>{{ formatDate(receiptData.collected_at) }}</span>
                          )
                        </i>
                      </span>
                    </div>
                  </div>
                  <div v-if="paymentInfo.value===PAYMENT_TYPE[1].value" class="mt-2">
                    <div class="d-flex">
                      <b-button
                        @click="handlePrintReceipts"
                        class="w-full"
                        variant="outline-primary"
                      >{{ $t('lbl_print_receipt') }}</b-button>
                    </div>
                  </div>
                  <div v-if="paymentInfo.value===PAYMENT_TYPE[2].value" class>
                    <div class="d-flex justify-content-end">
                      <span>
                        <i style="font-size:12px">
                          (
                          <span
                            v-if="receiptData.cancel_user"
                          >{{ `${receiptData.cancel_user.name} ` }}</span>
                          <span>{{ formatDate(receiptData.cancelled_at) }}</span>
                          )
                        </i>
                      </span>
                    </div>
                  </div>
                  <div v-if="paymentInfo.value===PAYMENT_TYPE[2].value" class="mt-1">
                    <div class="d-flex justify-content-between">
                      <span>{{ $t('lbl_reason') }}:</span>
                      <span>
                        <strong>{{ rejectReason }}</strong>
                      </span>
                    </div>
                  </div>
                </b-card>
              </div>-->
            </div>
            <div>
              <b-card>
                <h3>{{ $t("lbl_product_list") }}</h3>
                <b-overlay :show="false" rounded="sm" spinner-variant="primary" spinner-type="grow" spinner-small>
                  <b-table-simple class="cs-table" bordered responsive small>
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                    </colgroup>

                    <b-thead>
                      <b-tr>
                        <b-td v-for="(item, index) in tableColumnsPreview" :key="index" class="font-bold" rowspan="2"
                          colspan="1">
                          <div>{{ item.label }}</div>
                        </b-td>
                      </b-tr>
                    </b-thead>
                    <b-tbody v-for="(item, index) in products" :key="`${index}-${item.id}`">
                      <!-- <b-tr>
                        <b-th colspan="12">
                          {{ `${item.product.name} `}}
                          <span
                            v-if="item.shortage"
                          >{{ `(Thiếu ${item.shortage} trên ${item.request_qty} ${getUnit(item)})` }}</span>
                        </b-th>
                      </b-tr>-->
                      <b-tr>
                        <b-td class="text-center">{{ index + 1 }}</b-td>
                        <b-td width="400">{{ item.product.name }}</b-td>
                        <!-- <b-td>{{ item.product.quality }}</b-td> -->
                        <b-td class="text-center">{{ item.variant_qty }}</b-td>
                        <b-td class="text-center">{{ getUnit(item) }}</b-td>
                        <b-td class="text-center">
                          {{ item.product_lot_infor && item.product_lot_infor.name }}
                        </b-td>
                        <b-td class="text-center">
                          {{ formatDate(item.product_lot_infor.expiration_date, "DD/MM/YYYY") || "" }}
                        </b-td>
                        <!-- <b-td>{{ appUtils.numberFormat(item.product.price_in) }}</b-td> -->
                        <b-td class="text-right">{{ appUtils.numberFormat(item.product_variants.price) }}</b-td>
                        <!-- <b-td style="width:8%">{{(item.product.vat_in)s||'' }}</b-td>
                        <b-td style="width:8%">{{(item.product.vat_out)||'' }}</b-td>-->
                        <b-td class="text-right">{{ appUtils.numberFormat(item.total_price) }}</b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr>
                        <b-th colspan="6" class="text-right" style="font-weight: 500; font-size: 14px">
                          {{ $t("lbl_total_price") }}</b-th>
                        <b-th colspan="2" class="text-right" style="font-size: 14px">
                          {{ appUtils.numberFormat(salingData.totalPriceProduct || 0, "VNĐ") }}
                        </b-th>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                </b-overlay>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <b-card v-if="isPrintBill" class="cs-print">
        <div class="container cs-container">
          <div ref="documentPrint" id="documentPrint" class="h-full">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-6 text-center font-weight-bold"></div>
              <!-- <div class="col-4 d-flex justify-content-end cs-text-bold">
                <span>{{ $t("lbl_bill_code.product") }}:</span>
                <span class="ml-1">{{ salingData.stock_out_code }}</span>
              </div> -->
            </div>
            <div class="text-center mt-2">
              <h2 class="text-uppercase">{{ $t("lbl_saling") }}</h2>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <span>{{ $t("lbl_warehouse_name") }}:</span>
                <span class="ml-1 cs-text-bold" v-if="salingData.inventory">
                  {{ salingData.inventory.name }}
                </span>
              </div>
              <div class="col-6">
                <span>{{ $t("Tên Tổ chức") }}:</span>
                <span class="ml-1 cs-text-bold" v-if="salingData.inventory && salingData.inventory.supplier">
                  {{ salingData.inventory.supplier.name }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <span>{{ $t("lbl_full_name") }}:</span>
                <span class="ml-1 cs-text-bold">
                  {{ getCustomerInfo().name || "" }}
                </span>
              </div>
              <div class="col-6">
                <span>{{ $t("lbl_phone") }}:</span>
                <span class="ml-1 cs-text-bold">{{ getCustomerInfo().phone || "" }}</span>
              </div>
              <!-- <div class="col-3">
                <span>{{ $t('lbl_age_short') }}:</span>
                <span class="ml-1 cs-text-bold">{{ Number(salingData.customer_age)||'' }}</span>
              </div>-->
              <!-- <div class="col-3">
                <span>{{ $t('lbl_gender') }}:</span>
                <span
                  class="ml-1 cs-text-bold"
                >{{$t( salingData.customer_gender===1?'lbl_male':'lbl_female' )}}</span>
              </div>-->
            </div>
            <div class="mb-1">
              <span>{{ $t("lbl_address") }}:</span>
              <span class="ml-1 cs-text-bold">{{ getAddress(salingData) }}</span>
            </div>
            <b-overlay :show="false" rounded="sm" spinner-variant="primary" spinner-type="grow" spinner-small>
              <b-table-simple class="cs-saling" bordered responsive small>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>

                <b-thead>
                  <b-tr>
                    <b-td v-for="(item, index) in tableColumnsPreview" :key="index" class="font-bold" rowspan="2"
                      colspan="1">
                      <div>{{ item.label }}</div>
                    </b-td>
                  </b-tr>
                </b-thead>
                <b-tbody v-for="(item, index) in products" :key="`${index}-${item.id}`">
                  <!-- <b-tr>
                    <b-th colspan="12">
                      {{ `${item.product_info.name} `}}
                      <span
                        v-if="item.shortage"
                      >{{ `(Thiếu ${item.shortage} trên ${item.request_qty} ${getUnit(item)})` }}</span>
                    </b-th>
                  </b-tr>-->
                  <b-tr>
                    <b-td class="text-center">{{ index + 1 }}</b-td>
                    <b-td width="300">{{ item.product.name }}</b-td>
                    <!-- <b-td>{{ item.product.quality }}</b-td> -->
                    <b-td class="text-center">{{ item.variant_qty }}</b-td>
                    <b-td class="text-center">{{ getUnit(item) }}</b-td>
                    <b-td class="text-center">
                      {{ (item.product_lot_infor && item.product_lot_infor.name) || "" }}</b-td>
                    <b-td class="text-center">
                      {{ formatDate(item.product_lot_infor.expiration_date, "DD/MM/YYYY") || "" }}</b-td>
                    <!-- <b-td>{{ appUtils.numberFormat(item.product.price_in) }}</b-td> -->
                    <b-td class="text-right">{{ appUtils.numberFormat(item.product_variants.price) }}</b-td>
                    <!-- <b-td style="width:8%">{{(item.product.vat_in)s||'' }}</b-td>
                    <b-td style="width:8%">{{(item.product.vat_out)||'' }}</b-td>-->
                    <b-td class="text-right">{{ appUtils.numberFormat(item.total_price) }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-th colspan="6" class="text-right" style="font-weight: 500">{{ $t("lbl_total_price") }}</b-th>
                    <b-th colspan="2" class="text-right">
                      {{ appUtils.numberFormat(salingData.totalPriceProduct || 0, "VNĐ") }}</b-th>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-overlay>
            <div class="cs-text-bold mt-3">
              <div>
                <span>- {{ $t("lbl_total_price") }}:</span>
                <span v-if="salingData.totalPriceProduct" class="ml-1">
                  {{ formatPrice(salingData.totalPriceProduct) }}</span>
              </div>
              <div class="d-flex align-items-center">
                <span>- {{ $t("lbl_total_price_text") }}:</span>
                <span v-if="salingData.totalPriceProduct" class="ml-1 cs-capitalize">
                  <p class="m-0">
                    {{ `${appUtils.numberToWords(salingData.totalPriceProduct)}` }}
                  </p>
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4"></div>
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <div class="italic">
                  <i>{{ formatDate(salingData.date_add) }}</i>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4 text-center">
                <div>
                  <div class="cs-text-bold">
                    {{ $t("lbl_receiving_by_shot") }}
                  </div>
                  <div>
                    <i>{{ $t("lbl_sign_name") }}</i>
                  </div>
                  <div v-if="salingData.ecom_customer && salingData.ecom_customer.name" style="margin-top: 65px"
                    class="text-uppercase">
                    {{ salingData.ecom_customer && salingData.ecom_customer.name }}
                  </div>
                </div>
              </div>
              <div class="col-4 text-center">
                <div>
                  <div class="cs-text-bold">
                    {{ $t("lbl_created_receiving") }}
                  </div>
                  <div v-if="salingData.created_user" class="text-uppercase" style="margin-top: 95px">
                    {{ salingData.created_user.name }}
                  </div>
                </div>
              </div>
              <div class="col-4 text-center">
                <div>
                  <div class="cs-text-bold">{{ $t("lbl_cashier") }}</div>
                  <div class="text-center">
                    <i>{{ $t("lbl_sign_name") }}</i>
                  </div>
                  <div class="text-uppercase" v-if="receiptData.collect_user" style="margin-top: 65px">
                    {{ receiptData.collect_user.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <b-card v-if="isPrintReceipts" class="cs-print">
        <ReceiptPrint :receiptData="receiptData" />
      </b-card>
    </b-overlay>
    <b-modal v-model="isShowModalReject" :title="$t('lbl_canceld_bill')" :cancel-title="$t('lbl_canceled')"
      :ok-title="$t('lbl_confirm')" centered @ok="handleRejectBill">
      <validation-observer ref="rejectModal">
        <validation-provider #default="{ errors }" :name="$t('lbl_customer_name')" rules="required">
          <b-form-input v-model="rejectReason" :placeholder="$t('lbl_enter_reason_ticket')" />

          <small class="text-danger">{{
      errors[0] ? $t("lbl_required_reason") : ""
            }}</small>
        </validation-provider>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import appUtils from "@/utils/appUtils"
import { PAYMENT_TYPE } from "@/utils/constant"
import { required } from "@validations"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import vSelect from "vue-select"
import i18n from "../../../libs/i18n"
import ReceiptPrint from "../ReceiptManager/ReceiptPrint.vue"

const tableColumnsPreview = [
  { key: "index", label: "STT" },
  {
    key: "name",
    label: i18n.t("lbl_product_name_short"),
    class: "text-center",
  },
  {
    key: "qty",
    label: i18n.t("lbl_amount"),
    class: "text-center",
  },
  { key: "unit_id", label: i18n.t("lbl_units") },
  {
    key: "product_info.lot_number",
    label: i18n.t("lbl_lot_number"),
  },
  {
    key: "expried_date",
    label: i18n.t("lbl_expired_date"),
  },
  // {
  //   key: "price_in",
  //   label: i18n.t("lbl_price_in"),
  //   class: "text-right"
  // },
  {
    key: "price_out",
    label: i18n.t("lbl_price_out"),
    class: "text-right",
  },
  // {
  //   key: "product_info.vat_in",
  //   label: i18n.t("lbl_vat_in")
  // },
  // {
  //   key: "product_info.vat_out",
  //   label: i18n.t("lbl_vat_out")
  // },
  {
    key: "total_price",
    label: i18n.t("lbl_end_price"),
    class: "text-right",
  },
]

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "product_info.name",
    label: i18n.t("lbl_product_name_short"),
    sortable: false,
  },
  {
    key: "qty",
    label: i18n.t("lbl_amount"),

    sortable: false,
    class: "text-center",
  },
  { key: "unit_id", label: i18n.t("lbl_units"), sortable: false },
  {
    key: "price",
    label: i18n.t("lbl_price_vat"),
    class: "text-right",
  },
  {
    key: "total_price",
    label: i18n.t("lbl_end_price"),
    sortable: false,
    class: "text-right",
  },
]

export default {
  name: "SalingDetail",
  components: {
    // vSelect,
    ValidationProvider,
    ValidationObserver,
    ReceiptPrint,
  },
  data() {
    return {
      products: [],
      salingData: {},
      appUtils,
      tableColumns,
      PAYMENT_TYPE,
      paymentInfo: {},
      isOpenDropdown: false,
      isPrintBill: false,
      isPrintReceipts: false,
      isShowModalReject: false,
      rejectReason: "",
      required,
      hasSalingForm: false,
      isLoading: false,
      receiptData: {},
      tableColumnsPreview,
      medicinesPrint: [],
    }
  },
  computed: {
    userLocal() {
      return JSON.parse(localStorage.getItem("user"))
    },
  },
  watch: {
    $route() {
      this.handleViewDetail()
    },
  },
  async created() {
    await this.handleViewDetail()
  },
  methods: {
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ")
    },
    handlePrintBill() {
      this.isPrintReceipts = false
      this.isPrintBill = true

      setTimeout(() => {
        window.print()
      }, 500)
    },
    handlePrintReceipts() {
      this.isPrintBill = false

      this.isPrintReceipts = true

      setTimeout(() => {
        window.print()
      }, 500)
    },
    handleClose() {
      this.$router.go(-1)
    },
    async handleViewDetail() {
      const stockOutId = this.$route.params?.id
      if (!stockOutId) return

      try {
        const response = await this.$rf
          .getRequest("CommonRequest")
          .getDetailStockOut(stockOutId)
        const responseData = response?.data || {}

        this.products = (response?.data?.stock_out_detail || [])?.map(
          (item) => {
            const totalPrice =
              item?.product_variants?.price * item?.variant_qty

            return {
              ...item,
              total_price: Math.round((totalPrice * 100) / 100) || 0,
            }
          }
        )
        this.salingData = {
          ...responseData,
          totalPriceProduct: this.products?.reduce(
            (total, item) => total + item?.total_price,
            0
          ),
        }
      } catch (error) {
        console.log(error)
      }
    },
    // formatPrice(price) {
    //   return appUtils.numberFormat(Number(price), "VNĐ")
    // },
    getUnit(data) {
      return data?.product_variants?.product_unit?.name || ""
    },
    formatDate(timestamp, format = "HH:mm-DD/MM/YYYY") {
      if (!timestamp) return
      const date =
        String(timestamp).length === 10
          ? Math.round(timestamp * 1000)
          : timestamp

      return window.moment(date).format(format)
    },
    getAge(value) {
      return (
        value?.birthday &&
        (window.moment().diff(value.birthday, "years", false)
          ? window.moment().diff(value.birthday, "years", false)
          : "")
      )
    },
    getGender(value) {
      return value?.gender === 1 ? "Nam" : value?.gender === 2 ? "Nữ" : ""
    },
    getAddress() {
      let result = ""
      const customerInfo = this.getCustomerInfo()
      if (customerInfo?.address2) {
        result += customerInfo?.address2
      }
      if (customerInfo?.address1) {
        result = result + ", " + customerInfo?.address1
      }

      return result
    },
    handleEditSaling() {
      if (!this.$route.params.id) return

      this.$router.replace({
        name: "UpdateSaling",
        params: { id: this.$route.params.id },
      })
    },
    handleRejectBill(e) {
      e.preventDefault()

      this.$refs.rejectModal.validate().then((success) => {
        if (success) {
          this.isShowModalReject = false
        } else {
          return
        }
      })
    },
    handleSelectPaymentType(option) {
      this.isOpenDropdown = false
    },
    handleCloseDropDown() {
      this.isOpenDropdown = false
    },
    handleToggleDropdown() {
      this.isOpenDropdown = !this.isOpenDropdown
    },
    async handleUpdateStatus(status) {
      if (!this.salingData.id) return
    },
    async handleSubmitSalingForm() {
      if (!this.salingData.id) return
    },
    formatMoment(data) {
      if (
        !Number(data?.expiry_date_day) &&
        !Number(data?.expiry_date_month) &&
        !Number(data?.expiry_date_year)
      )
        return ""

      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
            ? `${data?.expiry_date_day}/`
            : "01/"

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
            ? `${data?.expiry_date_month}/`
            : "01/"
      return `${date}${month}${data?.expiry_date_year || ""}`
    },
    convertDateFormat(dateString) {
      if (!dateString) return ""
      var parts = dateString.split("/")
      return parts[2] + "-" + parts[1] + "-" + parts[0]
    },
    calculateTotalPrice(data) {
      const totalPrice = (
        data?.stock_out_detail?.variant_qty *
        data?.stock_out_detail?.product_variants?.price
      ).toFixed(2)

      return appUtils.numberFormat(totalPrice)
    },
    getCustomerInfo() {
      return (
        this.salingData.order_contact?.find(
          (itemContact) => itemContact?.type === 1 || itemContact?.type === 2
        ) || {}
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.cs-container {
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - 1px);
  max-width: 1000px;
  overflow: hidden;
}

.cs-container * {
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 26px;
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
  }

  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .cs-table {
    width: 1000px;
    margin-top: 16px;

    .w-6 {
      width: 6%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-15 {
      width: 15%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-8 {
      width: 8%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-10 {
      width: 10%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-12 {
      width: 12%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-13 {
      width: 13%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-16 {
      width: 16%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-18 {
      width: 18%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-20 {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-90 {
      width: 85%;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .table-item {
      border-top: unset;
    }

    .body-item {
      padding-top: 8px;
      padding-bottom: 8px;
      // border-right: 1px solid #787878;

      box-sizing: border-box;
    }
  }

  .cs-table * {
    font-size: 16px;
  }

  .border-t {
    border-top: 1px solid #787878;
  }

  .border-b {
    border-bottom: 1px solid #787878;
  }

  .border-l {
    border-left: 1px solid #787878;
  }

  .border-r {
    border-right: 1px solid #787878;
  }

  .cs-border {
    border: 1px solid #787878;
  }

  .cs-capitalize {
    text-transform: lowercase;

    p::first-letter {
      text-transform: capitalize;
    }
  }
}

.cs-relative {
  position: relative;
}

.cs-text-bold {
  font-weight: 600;
}

.cs-close {
  display: block;
  position: absolute;
  top: -50px;
  right: 40px;
  // height: 30px;
  // width: 30px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}

.cs-font * {
  font-size: 16px;

  h4 {
    font-size: 20px;
  }
}

.not-payment ::v-deep {
  background-color: rgb(243, 129, 8) !important;
  color: white !important;
}

.reject ::v-deep {
  color: white !important;
  background-color: red !important;
}

.payment ::v-deep {
  color: white !important;
  background-color: #17ad73 !important;
}

.status {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-select {
  background-color: #fff;
  position: absolute;
  bottom: -130px;
  right: 0;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cs-saling {
  max-width: 1000px;
}

.cs-menu-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  &:hover {
    background-color: rgba(21, 76, 205, 0.1);
  }

  span {
    display: block;
    // width: 100%;
    // height: 100%;
  }
}

.cs-item-active {
  background-color: #20419b !important;
  color: white;
}

::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 20px;
    text-transform: none !important;
  }

  tbody {
    font-size: 20px;
  }
}

.cs-print {
  display: none !important;
  margin: 0 !important;
}

.cs-print * {
  font-size: 20px;
}

@media print {
  .cs-print {
    display: block !important;
    overflow: hidden;
    margin-top: 30px;

    .card-body {
      padding: 0 !important;
    }
  }
}
</style>
